/* eslint-disable @typescript-eslint/no-misused-promises */
import { CnForm, ComponentContainer, ComponentTabs, ErrorBoundary, TextCellWithLabel } from '@flyward/platform'
import { AirframeDtoSchema, type Aircraft, type AirframeDto } from '@flyward/assets/models'
import { AirframeContractualTab } from './AirframeContractualTab'
import { AirframeTechnicalTab } from './AirframeTechnicalTab'
import { useEffect, useRef, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { AirframeLessorContributionsTab } from './AirframeLessorContributionsTab'
import { useUpdateAirframeMutation } from '@flyward/assets/store'
import { useAssetComponentsForms } from '@flyward/assets/context'
import { zodResolver } from '@hookform/resolvers/zod'

interface IAirframeDetailsProps {
  aircraft: Aircraft
  airframe: AirframeDto
}

export const AirframeDetails = ({ aircraft, airframe }: IAirframeDetailsProps): React.ReactElement<IAirframeDetailsProps> => {
  const { registerFormRef } = useAssetComponentsForms()
  const submitRef = useRef<HTMLInputElement>(null)

  const [currentTab, setCurrentTab] = useState<string>('Technical')

  const [updateAirframe] = useUpdateAirframeMutation()

  const onTabChanged = (activeTab: string) => {
    setCurrentTab(activeTab)
  }

  const form = useForm<AirframeDto>({
    defaultValues: airframe,
    values: airframe,
    resolver: zodResolver(AirframeDtoSchema),
  })

  const {
    register: registerAirframeFormField,
    handleSubmit: handleAirframeUpdate,
    formState,
    getValues: getAirframeFormValues,
    setValue: setAirframeFormValue,
    control: airframeFormControl,
    trigger: triggerAirframeValidation,
  } = form

  const airframeFormValues: AirframeDto = getAirframeFormValues()

  const { isDirty, isValid } = formState

  useEffect(() => {
    triggerAirframeValidation()
  }, [isValid, triggerAirframeValidation])

  useEffect(() => {
    registerFormRef({ id: [airframeFormValues.assetId, airframeFormValues.componentId], submitRef, hasChanges: isDirty, isValid })
  }, [registerFormRef, airframeFormValues, isDirty, isValid])

  const onUpdateAirframe: SubmitHandler<AirframeDto> = (data) => {
    updateAirframe({ assetId: airframe.assetId, airframe: data })
  }

  const tabs = {
    Technical: {
      content: (
        <ErrorBoundary>
          <AirframeTechnicalTab airframe={airframeFormValues} />
        </ErrorBoundary>
      ),
    },
    Contractual: {
      content: (
        <ErrorBoundary>
          <AirframeContractualTab airframe={airframeFormValues} />
        </ErrorBoundary>
      ),
    },
    'Lessor Contribution': {
      content: (
        <ErrorBoundary>
          <AirframeLessorContributionsTab
            key={airframeFormValues.componentId}
            airframe={airframeFormValues}
            registerFormField={registerAirframeFormField}
            setFormValue={setAirframeFormValue}
            formControl={airframeFormControl}
            formState={formState}
          />
        </ErrorBoundary>
      ),
    },
  }

  return (
    <ComponentContainer className="p-6">
      <div className="flex w-full flex-col">
        <p className="mb-4 w-full border-b border-primary-light-2 pb-2 text-base font-semibold text-text-1">Airframe</p>
        <div className="flex flex-col gap-y-2">
          <div className="flex gap-x-6">
            <TextCellWithLabel className="basis-1/6" label="Aircraft Type" info={aircraft.aircraftType} />
            <TextCellWithLabel className="basis-1/6" label="MSN" info={aircraft.serialNumber} />
            <div className="mr-18 basis-4/6" />
          </div>
          <ErrorBoundary>
            <CnForm {...form}>
              <form onSubmit={handleAirframeUpdate(onUpdateAirframe)}>
                <ComponentTabs
                  tabTriggerHeight="h-9"
                  tabTriggerLabelHeight="h-8"
                  tabs={tabs}
                  controlledActiveTab={currentTab}
                  onTabChanged={onTabChanged}
                />
                <input type="submit" className="hidden" ref={submitRef} />
              </form>
            </CnForm>
          </ErrorBoundary>
        </div>
      </div>
    </ComponentContainer>
  )
}
