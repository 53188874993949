export function parseTypeBoolean(value: string | null | boolean): boolean | null {
  if (typeof value === 'boolean') {
    return value
  } else if (value === 'true') {
    return true
  } else if (value === 'false') {
    return false
  } else {
    return null
  }
}

export function parseTypeNumber(value: string | null | boolean | number): number | null {
  if (typeof value === 'number') {
    return value
  } else if (typeof value === 'string' && !isNaN(Number(value))) {
    return Number(value)
  } else {
    return null
  }
}
