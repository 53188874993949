import { APIRoutes } from '@flyward/platform/constants'
import { http, HttpResponse } from 'msw'
import { type IGetAllEngineChecksWithDetailsByAssetIdQueryResponse, type IGetEnginesByAssetIdQueryResponse } from './types'
import { MaintenanceProgramType } from '@flyward/knowledgeBase/models/enums'

const enginesApiMocks = [
  http.get(APIRoutes.AssetsModule.Components.EnginesController.GetAll('*'), () => {
    return HttpResponse.json<IGetEnginesByAssetIdQueryResponse>(
      [
        {
          assignedKBProgramId: 'd4f857ae-c0bc-4e73-96e5-c7c99be7bf12',
          engineThrustRating: 27000,
          engineLifeLimitedParts: [
            {
              parentEngineId: '409b0912-fda6-47df-bd1a-bbb6aee84686',
              technicalLimitUnit: 3,
              technicalLimitAmount: 20000,
              componentModel: 'test',
              componentModule: 't',
              componentSerialNumber: 'JKH4168',
              partNumber: '5R0159',
              assetComponentUtilizationSnapshot: {
                componentId: 'bbc5299f-0f6d-4ae8-af72-3889825c6956',
                dateOfLastSnapshot: new Date('2023-02-23'),
                totalHoursSinceNew: 0,
                cyclesSinceNew: 0,
                remainingCycles: 0,
                componentUtilizationAtEvents: [],
                componentOpenUtilization: {
                  componentUtilizationSnapshotId: 'Kbc5p99f-0f6d-4ae8-af72-3889825c6956',
                  cyclesSinceEvent: 10,
                  hoursSinceEvent: 20,
                },
              },
              id: 'bbc5299f-0f6d-4ae8-af72-3889825c6956',
            },
          ],
          engineUnitUtilizationSnapshot: {
            aircraftEngineId: '409b0912-fda6-47df-bd1a-bbb6aee84686',
            engineEPRUtilizationSnapshot: {
              engineUnitUtilizationSnapshotId: 'efdf50d2-3652-4421-b5da-f828ad948b38',
              parentEngineId: '409b0912-fda6-47df-bd1a-bbb6aee84686',
              componentId: '409b0912-fda6-47df-bd1a-bbb6aee84686',
              dateOfLastSnapshot: new Date('2023-03-01'),
              totalHoursSinceNew: 0,
              cyclesSinceNew: 0,
              componentUtilizationAtEvents: [],
              componentOpenUtilization: {
                componentUtilizationSnapshotId: 'Kbc5p99f-0f6d-4ae8-af72-3889825c6956',
                cyclesSinceEvent: 10,
                hoursSinceEvent: 20,
              },
            },
            engineLLPUtilizationSnapshots: [
              {
                engineUnitUtilizationSnapshotId: 'efdf50d2-3652-4421-b5da-f828ad948b38',
                parentEngineId: '409b0912-fda6-47df-bd1a-bbb6aee84686',
                componentId: 'bbc5299f-0f6d-4ae8-af72-3889825c6956',
                componentOpenUtilization: {
                  componentUtilizationSnapshotId: 'Kbc5p99f-0f6d-4ae8-af72-3889825c6956',
                  cyclesSinceEvent: 10,
                  hoursSinceEvent: 20,
                },
                totalHoursSinceNew: 0,
                cyclesSinceNew: 0,
                remainingCycles: 0,
                componentUtilizationAtEvents: [],
                dateOfLastSnapshot: new Date('2023-02-23'),
                remainingHours: 100,
              },
            ],
            lifeLimitedPartDescriptor: 'HPC Rear Rotating Seal',
            firstLifeLimitedPartLimit: 0,
          },
          engineContract: {
            aircraftEngineId: '409b0912-fda6-47df-bd1a-bbb6aee84686',
            assetContractId: '606f3cbb-63d8-4aff-9d68-347041b46d33',
            componentContractDeliverySnapshot: {
              componentContractId: 'b73fd071-1d4c-4818-bca6-a8d142547b47',
              cyclesSinceLastEventAtContractDelivery: 18768,
              cyclesSinceNewAtContractDelivery: 18768,
              dateAtLastEventAtContractDelivery: '2018-10-04',
              totalHoursSinceLastEventAtContractDelivery: 27403,
              totalHoursSinceNewAtContractDelivery: 27403,
            },
            escalations: {
              eprEscalation: {
                maintenanceReserveAgreedEscalationPercentage: 3,
                endOfLeaseAgreedEscalationPercentage: 3,
                id: 'bcfd6dd0-f7f7-4547-b074-3227f6146c90',
                createdAt: new Date('2022-06-06'),
                lastModifiedAt: new Date('2021-06-06'),
              },
              llPsEscalation: {
                maintenanceReserveAgreedEscalationPercentage: 7,
                endOfLeaseAgreedEscalationPercentage: 7,
                id: '8e832773-2260-4689-a4f3-50325497ff7f',
                createdAt: new Date('2022-06-06'),
                lastModifiedAt: new Date('2021-06-06'),
              },
              id: '777c42eb-dba5-40b1-bc2b-62ca4609f337',
            },
            llPsContractualBuildStandard: 8000,
            llPsContractualBuildStandardUnit: 2,
            isFirstRun: false,
            engineUnitMaintenanceReserveContract: {
              isFirstRun: false,
              createdAt: new Date('2022-06-06'),
              lastModifiedAt: new Date('2021-06-06'),
              isMaintenanceReserveFundFlushAtEvent: true,
              enginePerformanceRestorationMaintenanceRate: {
                componentMaintenanceReserveId: 'e7c9054d-28d6-4d1d-8750-9ab5d753f08b',
                checkType: 1,
                utilizationUnit: 2,
                maintenanceReserveCollectionType: 1,
                ratesYear: 2023,
                rateAmount: 198,
                currentFund: 796141.69,
                id: '5e56dd90-c48f-4241-b360-085e3ce0442e',
                createdAt: new Date('2022-06-06'),
                lastModifiedAt: new Date('2021-06-06'),
                engineMaintenanceReserveId: 'e7c9054d-28d6-4d1d-8750-9ab5d753f08b',
              },
              engineLLPsTotalMaintenanceReserveRate: {
                componentMaintenanceReserveId: 'e7c9054d-28d6-4d1d-8750-9ab5d753f08b',
                checkType: 2,
                utilizationUnit: 3,
                maintenanceReserveCollectionType: 1,
                ratesYear: 2023,
                rateAmount: 19.7170762621657,
                currentFund: 53816.7845793638,
                id: 'a68ee3b4-362b-4208-98be-b9d6835bc76d',
                createdAt: new Date('2022-06-06'),
                lastModifiedAt: new Date('2021-06-06'),
                engineMaintenanceReserveId: 'e7c9054d-28d6-4d1d-8750-9ab5d753f08b',
              },
              isMaintenanceReserveActive: true,
              id: 'e7c9054d-28d6-4d1d-8750-9ab5d753f08b',
            },
            engineUnitEndOfLeaseContract: {
              enginePerformanceRestorationEndOfLeaseRate: {
                checkType: 1,
                rateAmount: 198,
                id: 'db7524d1-b958-40bc-93f8-47199f76d11e',
                createdAt: new Date('2022-06-06'),
                lastModifiedAt: new Date('2021-06-06'),
                typeOfEOLAdjustment: 1,
              },
              engineLLPsTotalEndOfLeaseRate: {
                checkType: 2,
                rateAmount: 19.7170762621657,
                id: '1287b7dd-be53-47af-88ea-741a24ab3d63',
                createdAt: new Date('2022-06-06'),
                lastModifiedAt: new Date('2021-06-06'),
                typeOfEOLAdjustment: 1,
              },
              isEOLApplicable: true,
              id: 'b543f24c-144f-4fc8-8941-5d015963d5dd',
              createdAt: new Date('2022-06-06'),
              lastModifiedAt: new Date('2021-06-06'),
            },
            minimumReDeliveryConditions: {
              createdAt: new Date('2022-06-06'),
              lastModifiedAt: new Date('2021-06-06'),
              llpMinimumReDeliveryConditions: [
                {
                  engineMinimumReDeliveryConditionId: '9ade4dfa-2112-4a53-92c8-50819d438e51',
                  minimumReDeliveryConditionType: 1,
                  minimumReDeliveryConditionUnit: 3,
                  value: 3500,
                  id: '154434d8-27e4-46d4-8211-2e3322773189',
                  createdAt: new Date('2022-06-06'),
                  lastModifiedAt: new Date('2021-06-06'),
                },
              ],
              eprMinimumReDeliveryConditions: [
                {
                  engineMinimumReDeliveryConditionId: '9ade4dfa-2112-4a53-92c8-50819d438e51',
                  minimumReDeliveryConditionType: 1,
                  minimumReDeliveryConditionUnit: 2,
                  value: 5000,
                  id: '082dbc5a-576c-4487-82fd-07f87a9aa55e',
                  createdAt: new Date('2022-06-06'),
                  lastModifiedAt: new Date('2021-06-06'),
                },
                {
                  engineMinimumReDeliveryConditionId: '9ade4dfa-2112-4a53-92c8-50819d438e51',
                  minimumReDeliveryConditionType: 1,
                  minimumReDeliveryConditionUnit: 3,
                  value: 3500,
                  id: 'b98f511d-70c5-4dd7-a438-e22e782a1cb3',
                  createdAt: new Date('2042-06-06'),
                  lastModifiedAt: new Date('2033-06-06'),
                },
              ],
              id: '9ade4dfa-2112-4a53-92c8-50819d438e51',
            },
            contractualFlightHoursToFlightCyclesPerformanceRestorationReserves: [
              {
                flightHoursToFlightCyclesRatio: 1.7,
                reserveAmount: 188,
                id: '9e3793c3-5489-4a53-bba9-406b7807111b',
                createdAt: new Date('2024-06-06'),
                lastModifiedAt: new Date('2023-06-06'),
              },
              {
                flightHoursToFlightCyclesRatio: 1.3,
                reserveAmount: 220,
                id: '53650396-b201-465b-bb1e-508f579438bd',
                createdAt: new Date('2024-06-06'),
                lastModifiedAt: new Date('2023-06-06'),
              },
            ],
          },
          componentId: '409b0912-fda6-47df-bd1a-bbb6aee84686',
          componentSerialNumber: 'V15770-one_llp-E_new',
          componentCategory: 2,
          componentModel: 'V2527-A5',

          manufacturingDetail: {
            componentId: '409b0912-fda6-47df-bd1a-bbb6aee84686',
            manufacturer: 'CFM International',
            dom: '2023-03-01',
          },
          assetId: 'bcb4ba13-90b5-4b38-a2c1-8adbdd8171f2',
          assetComponentUtilizationSnapshot: {
            componentId: 'bbc5299f-0f6d-4ae8-af72-3889825c6956',
            componentOpenUtilization: {
              componentUtilizationSnapshotId: 'd7c5299f-0f6d-4ae8-af72-3889825c6956',
              cyclesSinceEvent: 10,
              hoursSinceEvent: 20,
            },
            componentUtilizationAtEvents: [],
            cyclesSinceNew: 0,
            dateOfLastSnapshot: new Date('2023-02-23'),
            totalHoursSinceNew: 20,
          },
          componentContract: {
            componentContractDeliverySnapshot: {
              componentContractId: 'ca2d3fc3-ac58-422d-807a-228f58c16202',
              totalHoursSinceNewAtContractDelivery: 30946,
              cyclesSinceNewAtContractDelivery: 21156,
              dateAtLastEventAtContractDelivery: '2018-10-04',
              totalHoursSinceLastEventAtContractDelivery: null,
              cyclesSinceLastEventAtContractDelivery: null,
            },
          },
          partNumber: '5R0159',
        },
      ],
      { status: 200 },
    )
  }),
  http.get(APIRoutes.AssetsModule.Components.EnginesController.GetExpandedEngines('*'), () => {
    return HttpResponse.json<IGetAllEngineChecksWithDetailsByAssetIdQueryResponse>(
      [
        {
          id: 'comp-001',
          type: MaintenanceProgramType.Engine,
          serialNumber: 'SN-ABC123456',
          model: 'CFM56-7B24',
          totalHoursSinceNew: 12000,
          cyclesSinceNew: 5000,
          hoursSinceLastPr: 1000,
          cyclesSinceLastPr: 400,
          llpCyclesRemaining: 2000,
          llpDescriptor: 'Fan Disk',
          prFund: 75000,
          llpsFund: 125000,
        },
      ],
      { status: 200 },
    )
  }),
  http.put(APIRoutes.AssetsModule.Components.EnginesController.MaintenanceProgram('*'), () => {
    return HttpResponse.json({
      status: 204,
    })
  }),
]

export { enginesApiMocks }
