import type { AircraftEngine, ContractualFlightHoursToFlightCyclesPerformanceRestorationReserve } from '@flyward/assets/models'
import { ComponentTabs, ErrorBoundary, formatNumber, UtilizationUnitsDisplay } from '@flyward/platform'
import { ContractualReserveTab } from './ContractualReserveTab'
import { ContractualTab } from './ContractualTab'
import { LLPTechnicalTab } from './LLPTechnicalTab'
import { PRTechnicalTab } from './PRTechnicalTab'
import { useState } from 'react'
import { type ComponentUtilizationAtEvent } from '@flyward/platform/models/entities/Utilization'

interface IEngineDetailsTabsProps {
  engine: AircraftEngine
  lastPerformanceRestoration: ComponentUtilizationAtEvent | null
  performanceRestorationReserves: ContractualFlightHoursToFlightCyclesPerformanceRestorationReserve[]
}

const EngineDetailsTabs = ({ engine, lastPerformanceRestoration, performanceRestorationReserves }: IEngineDetailsTabsProps) => {
  const [currentTab, setCurrentTab] = useState<string>('PR Technical')
  const onTabChanged = (activeTab: string) => {
    setCurrentTab(activeTab)
  }
  const engineContract = engine.engineContract
  const utilizationSnapshot = engine.assetComponentUtilizationSnapshot
  const escalations = engineContract.escalations
  const maintenanceReserve = engineContract.engineUnitMaintenanceReserveContract
  const minimumReDeliveryConditions = engineContract.minimumReDeliveryConditions

  const eprMinimumReDeliveryConditions = minimumReDeliveryConditions.eprMinimumReDeliveryConditions
    .map((c) => `${formatNumber(c.value)} ${UtilizationUnitsDisplay(c.minimumReDeliveryConditionUnit)}`)
    .join('\n')

  const llpMinimumReDeliveryConditions = minimumReDeliveryConditions.llpMinimumReDeliveryConditions
    .map((c) => `${formatNumber(c.value)} ${UtilizationUnitsDisplay(c.minimumReDeliveryConditionUnit)}`)
    .join('\n')

  const tabs = {
    'PR Technical': {
      content: (
        <ErrorBoundary>
          <PRTechnicalTab
            cslpr={lastPerformanceRestoration?.cyclesSinceNewAtLastQualifyingEvent}
            csn={utilizationSnapshot.cyclesSinceNew}
            csnAtLastPR={lastPerformanceRestoration?.cyclesSinceNewAtLastQualifyingEvent}
            dateAtLastPR={lastPerformanceRestoration?.dateAtLastQualifyingEvent}
            lastPrDateAtContractDelivery={lastPerformanceRestoration?.dateAtLastQualifyingEvent}
            tslpr={lastPerformanceRestoration?.totalHoursSinceNewAtLastQualifyingEvent}
            tsn={utilizationSnapshot.totalHoursSinceNew}
            tsnAtLastPR={lastPerformanceRestoration?.totalHoursSinceNewAtLastQualifyingEvent}
          />
        </ErrorBoundary>
      ),
    },
    'LLP Technical': {
      content: <LLPTechnicalTab engineLifeLimitedParts={engine.engineLifeLimitedParts} />,
    },
    Contractual: {
      content: (
        <ErrorBoundary>
          <ContractualTab
            buildStandard={engineContract.llPsContractualBuildStandard}
            csnAtContractDelivery={engineContract.componentContractDeliverySnapshot?.cyclesSinceNewAtContractDelivery}
            currentLlpMrFund={maintenanceReserve.engineLLPsTotalMaintenanceReserveRate?.currentFund}
            currentPrMrFund={maintenanceReserve.enginePerformanceRestorationMaintenanceRate?.currentFund}
            isMrFlag={maintenanceReserve.isMaintenanceReserveActive}
            isMrFlagFirstRun={maintenanceReserve.isFirstRun}
            isMrFundFlushAtEvent={maintenanceReserve.isMaintenanceReserveFundFlushAtEvent}
            llpMrRate={maintenanceReserve.engineLLPsTotalMaintenanceReserveRate?.rateAmount}
            llpMrRateEscalation={escalations.llPsEscalation?.maintenanceReserveAgreedEscalationPercentage}
            llpMrRateType={engineContract.engineUnitMaintenanceReserveContract.engineLLPsTotalMaintenanceReserveRate?.utilizationUnit}
            mrRatesYear={maintenanceReserve.enginePerformanceRestorationMaintenanceRate?.ratesYear}
            prMrAgreedEscalation={escalations.eprEscalation?.maintenanceReserveAgreedEscalationPercentage}
            prMrRate={engineContract.engineUnitMaintenanceReserveContract.enginePerformanceRestorationMaintenanceRate?.rateAmount}
            prMrRateType={engineContract.engineUnitMaintenanceReserveContract.enginePerformanceRestorationMaintenanceRate?.utilizationUnit}
            returnConditionsLlp={llpMinimumReDeliveryConditions}
            returnConditionsPr={eprMinimumReDeliveryConditions}
            tsnAtContractDelivery={engineContract.componentContractDeliverySnapshot?.totalHoursSinceNewAtContractDelivery}
            isEOLApplicable={engineContract.engineUnitEndOfLeaseContract.isEOLApplicable}
            prEOLRate={engineContract.engineUnitEndOfLeaseContract.enginePerformanceRestorationEndOfLeaseRate}
          />
        </ErrorBoundary>
      ),
    },
    'Contractual FH to FC PR Reserve': {
      content: (
        <ErrorBoundary>
          <ContractualReserveTab performanceRestorationReserves={performanceRestorationReserves} />
        </ErrorBoundary>
      ),
    },
  }

  return (
    <ComponentTabs
      tabTriggerHeight="h-9"
      tabTriggerLabelHeight="h-8"
      tabTriggerWidth="w-auto"
      tabs={tabs}
      controlledActiveTab={currentTab}
      onTabChanged={onTabChanged}
    />
  )
}

export { EngineDetailsTabs }
export type { IEngineDetailsTabsProps }
