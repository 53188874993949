import {
  BooleanCellWithLabel,
  NumberCellWithLabel,
  NumberDisplayType,
  TextCellWithLabel,
  type UtilizationUnits,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { type ComponentEndOfLeaseRate } from '../../../../../../models/contract/ComponentEndOfLeaseRate'
import { EndOfLeaseRates } from '../../../../common/rates/EndOfLease'
import { isEmpty } from 'lodash'

interface IContractualTabProps {
  buildStandard: number
  csnAtContractDelivery: number
  currentLlpMrFund: number | null
  currentPrMrFund: number | null
  isMrFlag: boolean
  isMrFlagFirstRun: boolean
  isMrFundFlushAtEvent: boolean | null
  llpMrRate: number | null
  llpMrRateEscalation: number
  llpMrRateType: UtilizationUnits | null
  mrRatesYear: number | null
  prMrAgreedEscalation: number
  prMrRate: number | null
  prMrRateType: UtilizationUnits | null
  returnConditionsLlp: string
  returnConditionsPr: string
  tsnAtContractDelivery: number
  isEOLApplicable: boolean
  prEOLRate: ComponentEndOfLeaseRate
}

const ContractualTab = ({
  buildStandard,
  csnAtContractDelivery,
  currentLlpMrFund,
  currentPrMrFund,
  isMrFlag,
  isMrFlagFirstRun,
  isMrFundFlushAtEvent,
  llpMrRate,
  llpMrRateEscalation,
  llpMrRateType,
  mrRatesYear,
  prMrAgreedEscalation,
  prMrRate,
  prMrRateType,
  returnConditionsLlp,
  returnConditionsPr,
  tsnAtContractDelivery,
  isEOLApplicable,
  prEOLRate,
}: IContractualTabProps) => {
  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex gap-x-6">
        <NumberCellWithLabel className="basis-1/6" label="Build Standard" info={buildStandard} displayType={NumberDisplayType.Integer} />
        <BooleanCellWithLabel className="basis-1/6" label="MR Flag" info={isMrFlag} />
        <NumberCellWithLabel
          className="basis-1/6"
          label="MR rates Year"
          info={mrRatesYear}
          preventInfo={!isMrFlag}
          displayType={NumberDisplayType.Year}
        />
        <BooleanCellWithLabel className="basis-1/6" label="MR Flag First Run" info={isMrFlagFirstRun} preventInfo={!isMrFlag} />
        <NumberCellWithLabel
          className="basis-1/6"
          label="PR MR Agreed Escalation"
          info={prMrAgreedEscalation}
          preventInfo={!isMrFlag}
          displayType={NumberDisplayType.Percentage}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label="LLP MR Agreed Escalation"
          info={llpMrRateEscalation}
          preventInfo={!isMrFlag}
          displayType={NumberDisplayType.Percentage}
        />
      </div>
      <div className="flex gap-x-6">
        <NumberCellWithLabel
          className="basis-1/6"
          label="PR MR rate"
          info={prMrRate}
          preventInfo={!isMrFlag}
          currencyDecimals={2}
          displayType={NumberDisplayType.Currency}
        />
        <TextCellWithLabel className="basis-1/6" label="PR MR rate type" info={UtilizationUnitsDisplay(prMrRateType)} preventInfo={!isMrFlag} />
        <NumberCellWithLabel
          className="basis-1/6"
          label="LLP MR rate"
          info={llpMrRate}
          preventInfo={!isMrFlag}
          currencyDecimals={2}
          displayType={NumberDisplayType.Currency}
        />
        <TextCellWithLabel className="basis-1/6" label="LLP MR rate type" info={UtilizationUnitsDisplay(llpMrRateType)} preventInfo={!isMrFlag} />
        <NumberCellWithLabel
          className="basis-1/6"
          label="Current PR MR fund"
          info={currentPrMrFund}
          preventInfo={!isMrFlag}
          displayType={NumberDisplayType.CurrencyRounded}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label="Current LLP MR fund"
          info={currentLlpMrFund}
          preventInfo={!isMrFlag}
          displayType={NumberDisplayType.CurrencyRounded}
        />
      </div>
      <div className="flex gap-x-6">
        <BooleanCellWithLabel className="basis-1/6" label="MR Fund flush at event" info={isMrFundFlushAtEvent} preventInfo={!isMrFlag} />
        <NumberCellWithLabel
          className="basis-1/6"
          label="TSN @ contract delivery"
          info={tsnAtContractDelivery}
          displayType={NumberDisplayType.Integer}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label="CSN @ contract delivery"
          info={csnAtContractDelivery}
          displayType={NumberDisplayType.Integer}
        />
        <TextCellWithLabel
          className="basis-1/6 whitespace-pre"
          label="Return Conditions PR"
          info={isEmpty(returnConditionsPr) ? '-' : returnConditionsPr}
        />
        <TextCellWithLabel
          className="basis-1/6 whitespace-pre"
          label="Return Conditions LLP"
          info={isEmpty(returnConditionsLlp) ? '-' : returnConditionsLlp}
        />
        <div className="basis-1/6"></div>
      </div>
      <div className="flex gap-x-6">
        <BooleanCellWithLabel className="basis-1/6" label="EOL Applicable" info={isEOLApplicable} />
        <EndOfLeaseRates rates={[prEOLRate]} isEolApplicable={isEOLApplicable} />
        <div className="mr-12 basis-3/6"></div>
      </div>
    </div>
  )
}

export { ContractualTab }
export type { IContractualTabProps }
