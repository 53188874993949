import { type CheckTyeRateDTO } from '@flyward/platform/models/DTOs/CheckTyeRateDTO'
import { Input, InputType } from '@flyward/platform/components'
import { CheckTypesDisplay, ComponentCategoryDisplay, type CheckTypes } from '@flyward/platform/models'
import { cloneDeep, isNil } from 'lodash'
import { type FlyForwardParametersDTO } from '@flyward/platform/models/DTOs/FlyForwardParametersDTO'

interface IRatesParametersProps {
  flyForwardParameters: FlyForwardParametersDTO
  handleRateChange: (
    originalFlyForwardParameters: FlyForwardParametersDTO,
    masterComponentSerialNumber: string,
    rateType: 'mrRates' | 'eolRates',
    checkType: CheckTypes,
    field: keyof CheckTyeRateDTO,
    value: number,
  ) => void
}

const EditableRatesParameters = ({ flyForwardParameters, handleRateChange }: IRatesParametersProps) => {
  const sortedMasterComponentsRates = cloneDeep(flyForwardParameters.masterComponentsRates).sort((a, b) => {
    if (a.componentCategory < b.componentCategory) return -1
    if (a.componentCategory > b.componentCategory) return 1

    if (a.masterComponentSerialNumber < b.masterComponentSerialNumber) return -1
    if (a.masterComponentSerialNumber > b.masterComponentSerialNumber) return 1
    return 0
  })

  return (
    <div className="flex flex-col pt-4">
      {sortedMasterComponentsRates.map((masterComponentRate) => (
        <div key={masterComponentRate.masterComponentId + masterComponentRate.masterComponentSerialNumber} className="flex items-center gap-x-6 py-4">
          <div>
            <p className="pb-2 text-base font-semibold text-gray-700">
              {ComponentCategoryDisplay(masterComponentRate.componentCategory)} {masterComponentRate.masterComponentSerialNumber}
            </p>
            <div className="flex gap-x-6">
              {masterComponentRate.mrRates.map((mrRate) => {
                return (
                  <Input
                    key={`${masterComponentRate.masterComponentSerialNumber}-mr-${mrRate.checkType}-amount`}
                    label={`${CheckTypesDisplay(mrRate.checkType)} MR Rate`}
                    controlledValue={String(mrRate.rateAmount)}
                    setValueAfterValidation={(value) => {
                      value !== String(mrRate.rateAmount) &&
                        handleRateChange(
                          flyForwardParameters,
                          masterComponentRate.masterComponentSerialNumber,
                          'mrRates',
                          mrRate.checkType,
                          'rateAmount',
                          parseFloat(value),
                        )
                    }}
                    type={InputType.Decimal}
                    inputClassName="w-65"
                  />
                )
              })}
              {masterComponentRate.mrRates.map((mrRate) => {
                const controlledValue = isNil(mrRate.rateFund) ? undefined : String(Number(mrRate.rateFund).toFixed(0))
                return (
                  <Input
                    key={`${masterComponentRate.masterComponentSerialNumber}-mr-${mrRate.checkType}-fund`}
                    label={`Current ${CheckTypesDisplay(mrRate.checkType)} MR Fund`}
                    controlledValue={controlledValue}
                    setValueAfterValidation={(value) => {
                      value !== String(Number(mrRate.rateFund).toFixed(0)) &&
                        handleRateChange(
                          flyForwardParameters,
                          masterComponentRate.masterComponentSerialNumber,
                          'mrRates',
                          mrRate.checkType,
                          'rateFund',
                          parseInt(value),
                        )
                    }}
                    type={InputType.NaturalNumber}
                    inputClassName="w-65"
                  />
                )
              })}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export { EditableRatesParameters }
