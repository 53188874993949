import { type ComponentContractDeliverySnapshot } from './ComponentContractDeliverySnapshot'
import { z } from 'zod'

export interface ComponentContractDto {
  componentContractDeliverySnapshot: ComponentContractDeliverySnapshot
}

export const ComponentContractDtoSchema = z.object({
  componentContractDeliverySnapshot: z.any().optional().nullable(),
})
