import { ComponentSchema, type ComponentDto } from '../../ComponentDto'
import { type AirframeContract } from './airframeContract'
import { AirframeLessorContributionSchema, type AirframeLessorContributionDto } from './airframeLessorContributionDto'
import { type AirframeUtilizationSnapshot } from './airframeUtilizationSnapshot'
import { z } from 'zod'

export interface AirframeDto extends ComponentDto {
  airframeUtilizationSnapshot: AirframeUtilizationSnapshot
  airframeContract: AirframeContract
  lessorContributions: AirframeLessorContributionDto[]
}

export const AirframeDtoSchema = ComponentSchema.extend({
  airframeUtilizationSnapshot: z.any(),
  airframeContract: z.any(),
  lessorContributions: z.array(AirframeLessorContributionSchema),
})
