export const refineNaturalNumber = {
  check: (value: string | number | null): boolean => {
    const stringValue = (value as string) ?? ''
    return /^\d+$/.test(stringValue)
  },
  message: 'Field should contain only natural numbers',
}

export const refineNonNegative = {
  check: (value: number): boolean => value >= 0,
  message: 'Field must be greater than or equal to 0',
}

export const transformToNumber = (value: string | null): number => {
  const parsedValue = parseFloat(value ?? '')
  return isNaN(parsedValue) ? 0 : parsedValue
}
