import { EndOfLeaseRates } from '@flyward/assets/components/AircraftComponents/common'
import { type AirframeDto, type ComponentMaintenanceReserveRate, type AirframeMinimumReDeliveryCondition } from '@flyward/assets/models'
import {
  BooleanCellWithLabel,
  CheckTypesDisplay,
  cn,
  formatNumber,
  MaintenanceReserveCollectionTypeDisplay,
  MinimumReDeliveryConditionTypeDisplay,
  NumberCellWithLabel,
  NumberDisplayType,
  TextCellWithLabel,
  UtilizationUnitsDisplay,
} from '@flyward/platform'
import { cloneDeep } from 'lodash'

interface IAirframeContractualTabProps {
  airframe: AirframeDto
}

export const AirframeContractualTab = ({ airframe }: IAirframeContractualTabProps) => {
  const contractDeliverySnapshot = airframe.airframeContract.componentContractDeliverySnapshot
  const maintenanceReserve = airframe.airframeContract.airframeMaintenanceReserveContract
  const rates = maintenanceReserve.airframeMaintenanceRates
  const minimumRedeliveryConditions = airframe.airframeContract.minimumReDeliveryConditions

  const Rates = cloneDeep(rates)
    .sort((a, b) => Number(a.checkType) - Number(b.checkType))
    .map((rate: ComponentMaintenanceReserveRate) => {
      if (rate === undefined) return null
      return (
        <div key={rate.checkType} className="flex w-full gap-x-6 gap-y-4">
          <NumberCellWithLabel
            className="basis-1/6"
            label={`${CheckTypesDisplay(rate.checkType)} MR rate`}
            info={rate.rateAmount}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
            displayType={NumberDisplayType.Currency}
          />
          <TextCellWithLabel
            className="basis-1/6"
            label={`${CheckTypesDisplay(rate.checkType)} rate type`}
            info={UtilizationUnitsDisplay(rate.utilizationUnit)}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
          />
          <NumberCellWithLabel
            className="basis-1/6"
            label={`${CheckTypesDisplay(rate.checkType)} rate year`}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
            info={rate.ratesYear}
            displayType={NumberDisplayType.Year}
          />
          <TextCellWithLabel
            className="basis-1/6"
            label={`${CheckTypesDisplay(rate.checkType)} MR collection type`}
            info={MaintenanceReserveCollectionTypeDisplay(rate.maintenanceReserveCollectionType)}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
          />
          <NumberCellWithLabel
            className="basis-1/6"
            label={`Current ${CheckTypesDisplay(rate.checkType)} MR fund`}
            info={rate.currentFund}
            preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
            displayType={NumberDisplayType.CurrencyRounded}
          />
          <div className="basis-1/6" />
        </div>
      )
    })

  const minimumRedeliveryConditionsDisplay: string = minimumRedeliveryConditions
    ?.map((minimumRedeliveryCondition: AirframeMinimumReDeliveryCondition) => {
      return `${MinimumReDeliveryConditionTypeDisplay(
        minimumRedeliveryCondition.minimumReDeliveryConditionType,
      )} - ${formatNumber(minimumRedeliveryCondition.value)} ${UtilizationUnitsDisplay(minimumRedeliveryCondition.minimumReDeliveryConditionUnit)}`
    })
    .join('\n')
  return (
    <div className="flex w-full flex-col gap-y-2">
      <div className="flex w-full gap-6">
        <BooleanCellWithLabel className="basis-1/6" label="MR Active" info={maintenanceReserve.isMaintenanceReserveActive} />
        <BooleanCellWithLabel
          className="basis-1/6"
          label="MR Fund flushed at event"
          info={maintenanceReserve.isMaintenanceReserveFundFlushAtEvent}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label="MR Escalation"
          info={airframe.airframeContract.escalations?.maintenanceReserveAgreedEscalationPercentage}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
          displayType={NumberDisplayType.Percentage}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label="Man. Escalation"
          info={airframe.airframeContract.escalations?.manEscalation}
          preventInfo={!maintenanceReserve.isMaintenanceReserveActive}
          displayType={NumberDisplayType.Percentage}
        />
        {/** TODO remove when MR Payer is added */}
        <div className="mr-6 basis-2/6" />
      </div>
      <div className="flex w-full flex-col gap-y-2">{Rates}</div>
      <div className="flex gap-x-6">
        <BooleanCellWithLabel
          className="basis-1/6"
          label="EOL Applicable"
          info={airframe.airframeContract?.airframeEndOfLeaseContract?.isEOLApplicable}
        />
        <EndOfLeaseRates
          rates={airframe.airframeContract.airframeEndOfLeaseContract?.airframeEndOfLeaseRates}
          isEolApplicable={airframe.airframeContract?.airframeEndOfLeaseContract?.isEOLApplicable}
        />
        <div
          className={cn(
            // TODO: refactor this, we should not be forced to have flex basis
            `basis-${Math.floor(3 / Math.max(airframe.airframeContract.airframeEndOfLeaseContract?.airframeEndOfLeaseRates.length, 1))}/6`,
            airframe.airframeContract.airframeEndOfLeaseContract?.airframeEndOfLeaseRates.length < 2 ? 'mr-12' : '',
          )}
        />
      </div>
      <div className="flex gap-x-6">
        <NumberCellWithLabel
          className="basis-1/6"
          label="TSN @ Contract Delivery"
          info={contractDeliverySnapshot?.totalHoursSinceNewAtContractDelivery}
          displayType={NumberDisplayType.Integer}
        />
        <NumberCellWithLabel
          className="basis-1/6"
          label="CSN @ Contract Delivery"
          info={contractDeliverySnapshot?.cyclesSinceNewAtContractDelivery}
          displayType={NumberDisplayType.Integer}
        />
        <TextCellWithLabel className="basis-1/6" label="LOC Periodicity" info="N/A" />
        <TextCellWithLabel className="basis-2/6 whitespace-pre" label="Redelivery Conditions" info={minimumRedeliveryConditionsDisplay} />
        <div className="mr-6 basis-1/6" />
      </div>
    </div>
  )
}
