import { type AirframeDto } from '@flyward/assets/models'
import { type AirframeLessorContributionDto } from '@flyward/assets/models/aircraftComponents/airframe/airframeLessorContributionDto'
import {
  AirframeCheckTypeDisplay,
  CnFormControl,
  CnFormField,
  CnFormItem,
  CnFormMessage,
  Input,
  InputType,
  parseTypeBoolean,
  SimpleHeader,
} from '@flyward/platform'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { type UseFormSetValue, type UseFormRegister, type Control, type FormState } from 'react-hook-form'

interface IAirframeLessorContributionsEditableTabProps {
  airframe: AirframeDto
  register: UseFormRegister<AirframeDto>
  setFormValue: UseFormSetValue<AirframeDto>
  formControl: Control<AirframeDto, unknown>
  formState: FormState<AirframeDto>
}

const generateGridColumns = (
  lessorContributions: AirframeLessorContributionDto[],
  register: UseFormRegister<AirframeDto>,
  setFormValue: UseFormSetValue<AirframeDto>,
  formControl: Control<AirframeDto, unknown>,
  formState: FormState<AirframeDto>,
): Array<ColumnDef<AirframeLessorContributionDto>> => {
  const contractualReserveColumns: Array<ColumnDef<AirframeLessorContributionDto>> = [
    {
      id: 'checkType',
      accessorKey: 'checkType',
      header: () => <SimpleHeader title="Check Type" className="text-semibold m-0 w-full p-1 text-xs text-black-0" />,
      cell: ({ row }) => <div className="w-24 min-w-24 text-xs">{AirframeCheckTypeDisplay(row.original.checkType)}</div>,
    },
    {
      id: 'isActive',
      accessorKey: 'isActive',
      header: () => <SimpleHeader title="Applicable" className="text-semibold m-0 w-full p-1 text-xs text-black-0" />,
      cell: ({ row }) => (
        <div className="flex w-24 min-w-24 text-xs">
          <select
            {...register(`lessorContributions.${row.index}.lessorContribution.isActive`, { setValueAs: parseTypeBoolean })}
            defaultValue={row.original.lessorContribution?.isActive?.toString() ?? 'false'}
          >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
      ),
    },
    {
      id: 'isUnlimited',
      accessorKey: 'isUnlimited',
      header: () => <SimpleHeader title="Unlimited" className="text-semibold m-0 w-full p-1 text-xs text-black-0" />,
      cell: ({ row }) => {
        if (lessorContributions[row.index].lessorContribution?.isActive === true) {
          return (
            <div className="flex w-24 min-w-24 text-xs">
              <select
                {...register(`lessorContributions.${row.index}.lessorContribution.isUnlimited`, { setValueAs: parseTypeBoolean })}
                defaultValue={row.original.lessorContribution?.isUnlimited?.toString() ?? 'false'}
              >
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          )
        } else {
          setFormValue(`lessorContributions.${row.index}.lessorContribution.isUnlimited`, false)
          setFormValue(`lessorContributions.${row.index}.lessorContribution.amount`, 0)
          return <div className="flex h-5 w-24 min-w-24 bg-black-20"></div>
        }
      },
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      header: () => <SimpleHeader title="Total Amount" className="text-semibold m-0 w-full p-1 text-xs text-black-0" />,
      cell: ({ row }) => {
        if (row.original.lessorContribution?.isUnlimited !== true && row.original.lessorContribution?.isActive === true) {
          return (
            <div className="w-32 min-w-32 text-xs">
              <CnFormField
                control={formControl}
                name={`lessorContributions.${row.index}.lessorContribution.amount`}
                render={({ field }) => {
                  return (
                    <CnFormItem>
                      <CnFormControl>
                        <Input
                          inputClassName="w-full h-5"
                          type={InputType.NaturalNumber}
                          hasAutoFocus={formState?.dirtyFields.lessorContributions?.[row.index]?.lessorContribution?.amount}
                          {...field}
                        />
                      </CnFormControl>
                      <CnFormMessage />
                    </CnFormItem>
                  )
                }}
              />
            </div>
          )
        } else {
          setFormValue(`lessorContributions.${row.index}.lessorContribution.amount`, 0)
          return <div className="flex h-5 w-32 min-w-32 bg-black-20"></div>
        }
      },
    },
  ]

  return contractualReserveColumns
}

export const AirframeLessorContributionsEditableTab = ({
  airframe,
  register,
  setFormValue,
  formControl,
  formState,
}: IAirframeLessorContributionsEditableTabProps) => {
  const lessorContributions = airframe.lessorContributions

  const contractualReserveColumns = generateGridColumns(lessorContributions, register, setFormValue, formControl, formState)

  const contractualReserveTable = useReactTable<AirframeLessorContributionDto>({
    data: lessorContributions,
    columns: contractualReserveColumns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return <GenericTable {...contractualReserveTable} />
}
