import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { PageContainer } from '../../../layout'
import { ErrorBoundary, OutletTabs, useHeaderContent, useNavigationState, useToast } from '@flyward/platform'
import { EngineTechSpec } from '@flyward/assets'
import { useGetAllEnginesByAssetIdQuery, useGetAllStandaloneEnginesByAssetIdQuery } from '@flyward/platform/store'
import { StandaloneEngineMaintenanceProgramSelection } from './StandaloneEngineMaintenanceProgramSelection'

export const StandaloneEnginePageBody = () => {
  const { toast } = useToast()

  const { id } = useParams()
  const { setTitle, setHasBackButton } = useHeaderContent()
  const { toggleVisibility } = useNavigationState()

  const assetId: string = id ?? ''

  const { data: engines } = useGetAllEnginesByAssetIdQuery({ assetId, includeLLPs: true })
  const { data: standaloneEngines } = useGetAllStandaloneEnginesByAssetIdQuery({ assetId })

  useEffect(() => {
    toggleVisibility()
    setTitle('View Engine')
    setHasBackButton(true)

    return () => {
      toggleVisibility()
    }
  }, [setHasBackButton, setTitle, toggleVisibility, assetId, toast])

  const engineAssignedKBProgramId = engines?.[0]?.assignedKBProgramId ?? ''

  const tabs = {
    'Tech Spec': {
      content: <EngineTechSpec key={assetId} engine={engines?.[0]} standAloneEngine={standaloneEngines} />,
    },
    'Maintenance Program': {
      content: (
        <StandaloneEngineMaintenanceProgramSelection
          key={`${assetId}-${engineAssignedKBProgramId}`}
          assetId={assetId}
          engineAssignedKBProgramId={engineAssignedKBProgramId}
        />
      ),
    },
  }

  return (
    <PageContainer>
      <ErrorBoundary>
        <OutletTabs tabs={tabs} />
      </ErrorBoundary>
    </PageContainer>
  )
}
