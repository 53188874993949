import { EngineMaintenanceProgram } from '@flyward/assets'
import { ErrorBoundary } from '@flyward/platform'
import { useGetAllEngineMaintenanceProgramsQuery, useUpdateEnginesMaintenanceProgramMutation } from '@flyward/platform/store'

interface IStandaloneEngineMaintenanceProgramSelectionProps {
  assetId: string
  engineAssignedKBProgramId: string
}

export const StandaloneEngineMaintenanceProgramSelection = ({
  assetId,
  engineAssignedKBProgramId,
}: IStandaloneEngineMaintenanceProgramSelectionProps) => {
  const [updateEngineMaintenanceProgram] = useUpdateEnginesMaintenanceProgramMutation()

  const { data: availableEnginePrograms } = useGetAllEngineMaintenanceProgramsQuery(false)

  const changeEngineProgram = (engineProgramId: string) => {
    updateEngineMaintenanceProgram({ assetId, kbProgramId: engineProgramId })
  }

  return (
    <ErrorBoundary>
      <EngineMaintenanceProgram
        assetId={assetId}
        availablePrograms={{
          availableEnginePrograms:
            availableEnginePrograms?.map((program) => {
              return {
                value: program.masterComponentKBProgramId,
                label: program.maintenanceProgramName,
              }
            }) ?? [],
        }}
        changePrograms={{
          changeEngineProgram,
        }}
        assignedEngineProgramId={engineAssignedKBProgramId}
      />
    </ErrorBoundary>
  )
}
