import { type AirframeDto } from '@flyward/assets/models'
import { type AirframeLessorContributionDto } from '@flyward/assets/models/aircraftComponents/airframe/airframeLessorContributionDto'
import { AirframeCheckTypeDisplay, NumberDisplay, NumberDisplayType, SimpleHeader } from '@flyward/platform'
import { GenericTable } from '@flyward/platform/components/Table/GenericTable'
import { type ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table'

interface IAirframeLessorContributionsReadonlyTabProps {
  airframe: AirframeDto
}

const generateGridColumns = (): Array<ColumnDef<AirframeLessorContributionDto>> => {
  const contractualReserveColumns: Array<ColumnDef<AirframeLessorContributionDto>> = [
    {
      id: 'checkType',
      accessorKey: 'checkType',
      header: () => <SimpleHeader title="Check Type" className="text-semibold m-0 w-full p-1 text-xs text-black-0" />,
      cell: ({ row }) => <div className="w-24 min-w-24 text-xs">{AirframeCheckTypeDisplay(row.original.checkType)}</div>,
    },
    {
      id: 'isActive',
      accessorKey: 'isActive',
      header: () => <SimpleHeader title="Applicable" className="text-semibold m-0 w-full p-1 text-xs text-black-0" />,
      cell: ({ row }) => <div className="flex w-24 min-w-24 text-xs">{row.original.lessorContribution?.isActive === true ? 'Yes' : 'No'}</div>,
    },
    {
      id: 'isUnlimited',
      accessorKey: 'isUnlimited',
      header: () => <SimpleHeader title="Unlimited" className="text-semibold m-0 w-full p-1 text-xs text-black-0" />,
      cell: ({ row }) => <div className="flex w-24 min-w-24 text-xs">{row.original.lessorContribution?.isUnlimited === true ? 'Yes' : 'No'}</div>,
    },
    {
      id: 'amount',
      accessorKey: 'amount',
      header: () => <SimpleHeader title="Total Amount" className="text-semibold m-0 w-full p-1 text-xs text-black-0" />,
      cell: ({ row }) => (
        <div className="w-32 min-w-32 text-xs">
          <NumberDisplay
            displayType={NumberDisplayType.CurrencyRounded}
            className="w-32 min-w-32"
            value={row.original.lessorContribution?.amount ?? 0}
          />
        </div>
      ),
    },
  ]

  return contractualReserveColumns
}

export const AirframeLessorContributionsReadonlyTab = ({ airframe }: IAirframeLessorContributionsReadonlyTabProps) => {
  const lessorContributions: AirframeLessorContributionDto[] = airframe.lessorContributions

  const contractualReserveColumns = generateGridColumns()

  const contractualReserveTable = useReactTable<AirframeLessorContributionDto>({
    data: lessorContributions,
    columns: contractualReserveColumns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    enableMultiRowSelection: false,
  })

  return <GenericTable {...contractualReserveTable} />
}
