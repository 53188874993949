/* eslint-disable @typescript-eslint/no-misused-promises */
import { useAssetComponentsForms } from '@flyward/assets/context/AssetComponentsFormsContext'
import {
  StandaloneEngineAssignedMaintenanceProgramsSchema,
  type StandaloneEngineAssignedMaintenanceProgram,
  type StandaloneEngineMaintenancePrograms,
} from '@flyward/assets/models'
import { CnForm, CnFormControl, CnFormField, CnFormItem, CnFormMessage, Combobox } from '@flyward/platform'
import { zodResolver } from '@hookform/resolvers/zod'
import { isNil } from 'lodash'
import { useEffect, useRef } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'

export const EditEngineMaintenanceProgram = ({
  assetId,
  assignedEngineProgramId,
  availablePrograms,
  changePrograms,
}: StandaloneEngineMaintenancePrograms) => {
  const { registerFormRef } = useAssetComponentsForms()
  const submitRef = useRef<HTMLInputElement>(null)

  const form = useForm<StandaloneEngineAssignedMaintenanceProgram>({
    defaultValues: { assignedEngineProgramId },
    values: { assignedEngineProgramId },
    resolver: zodResolver(StandaloneEngineAssignedMaintenanceProgramsSchema),
  })

  const {
    handleSubmit: handleStandaloneEngineMaintenanceProgramsUpdate,
    formState,
    getValues: getStandaloneEngineMaintenanceProgramsFormValues,
    setValue: setStandaloneEngineMaintenanceProgramsFormValue,
    control: aircraftMaintenanceProgramsFormControl,
  } = form

  const onUpdateStandaloneEngineMaintenancePrograms: SubmitHandler<StandaloneEngineAssignedMaintenanceProgram> = (data) => {
    if (!isNil(data.assignedEngineProgramId)) {
      changePrograms.changeEngineProgram(data.assignedEngineProgramId)
    }
  }

  const aircraftMaintenanceProgramsFormValues = getStandaloneEngineMaintenanceProgramsFormValues()

  const { isDirty, dirtyFields, isValid } = formState

  useEffect(() => {
    registerFormRef({ id: [assetId, 'standaloneEngineMaintenanceProgram'], submitRef, hasChanges: isDirty, isValid })
  }, [
    assetId,
    registerFormRef,
    dirtyFields,
    aircraftMaintenanceProgramsFormValues,
    isDirty,
    isValid,
    setStandaloneEngineMaintenanceProgramsFormValue,
  ])

  return (
    <CnForm {...form}>
      <form onSubmit={handleStandaloneEngineMaintenanceProgramsUpdate(onUpdateStandaloneEngineMaintenancePrograms)}>
        <div className="flex w-full gap-x-6">
          <CnFormField
            control={aircraftMaintenanceProgramsFormControl}
            name={`assignedEngineProgramId`}
            render={({ field }) => (
              <CnFormItem>
                <CnFormControl>
                  <Combobox
                    items={availablePrograms.availableEnginePrograms}
                    itemLabel="engine program"
                    className="basis-3/12"
                    label="Assign Engine Program"
                    selectedValues={isNil(field.value) ? [] : [field.value?.toString()]}
                    setSelectedValues={(value: string[]) => {
                      field.onChange(value[0])
                    }}
                  />
                </CnFormControl>
                <CnFormMessage />
              </CnFormItem>
            )}
          />
          <div className="mr-12 basis-2/6"></div>
          <input type="submit" className="hidden" ref={submitRef} />
        </div>
      </form>
    </CnForm>
  )
}
