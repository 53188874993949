import { type ComponentUtilizationSnapshot, type ComponentCategory } from '@flyward/platform/models'
import { type ManufacturingDetail } from './ManufacturingDetail'
import { z } from 'zod'
import { type ComponentContractDto, ComponentContractDtoSchema } from './contract/ComponentContractDto'

export interface ComponentDto {
  assetId: string

  componentId: string
  componentSerialNumber: string
  componentCategory: ComponentCategory
  componentModel: string

  partNumber?: string

  manufacturingDetail: ManufacturingDetail

  assetComponentUtilizationSnapshot: ComponentUtilizationSnapshot
  componentContract: ComponentContractDto

  assignedKBProgramId?: string
}

export const ComponentSchema = z.object({
  assetId: z.string(),
  componentId: z.string(),
  componentSerialNumber: z.string(),
  componentCategory: z.any(),
  componentModel: z.any(),

  partNumber: z.string().optional().nullable(),

  manufacturingDetail: z.any(),

  assetComponentUtilizationSnapshot: z.any(),
  componentContract: ComponentContractDtoSchema.optional().nullable(),

  assignedKBProgramId: z.string().optional().nullable(),
})
