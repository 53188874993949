import { AuthorizedElement } from '@flyward/appIdentity/context/AuthorizedRole/AuthorizedElement'
import { useAssetComponentsForms } from '@flyward/assets/context'
import { Button, ButtonVariant, IconVariant, Size } from '@flyward/platform/components'

export const AircraftPageHeader = () => {
  const { hasAnyChanges, isAllValid, isInEditMode, setIsInEditMode, getAllFormRefs } = useAssetComponentsForms()

  const allFormRefs = getAllFormRefs()

  const submitAllForms = () => {
    allFormRefs.forEach((formRef) => {
      if (formRef.submitRef?.current !== null) {
        formRef.submitRef.current.click()
      }
    })
  }

  return (
    <AuthorizedElement>
      <div data-permission-element-id="edit-asset">
        {!isInEditMode && (
          <div className="absolute right-0 top-0 mr-4 mt-4">
            <Button
              variant={ButtonVariant.Primary}
              size={Size.Medium}
              leftIcon={IconVariant.Edit}
              label="Edit"
              key={'edit-aircraft'}
              labelClassName={`hidden group-hover:block`}
              onClick={() => {
                setIsInEditMode(true)
              }}
            />
          </div>
        )}
        {hasAnyChanges && (
          <div className="absolute right-0 top-0 mr-4 mt-4">
            <Button
              variant={ButtonVariant.Primary}
              disabled={!isAllValid}
              size={Size.Medium}
              leftIcon={IconVariant.Save}
              key={'update-aircraft'}
              label="Save"
              labelClassName={`hidden group-hover:block`}
              onClick={() => {
                submitAllForms()
                setIsInEditMode(false)
              }}
            />
          </div>
        )}
      </div>
    </AuthorizedElement>
  )
}
