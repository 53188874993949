import { refineNaturalNumber, refineNonNegative, transformToNumber } from '@flyward/platform/helpers'
import { z } from 'zod'

export interface LessorContributionDto {
  isActive: boolean
  isUnlimited: boolean
  amount: number
}

export const LessorContributionDtoSchema = z.object({
  isActive: z.any().optional().nullable(),
  isUnlimited: z.any().optional().nullable(),
  amount: z
    .any()
    .optional()
    .nullable()
    .refine(refineNaturalNumber.check, {
      message: refineNaturalNumber.message,
    })
    .transform(transformToNumber)
    .refine(refineNonNegative.check, {
      message: refineNonNegative.message,
    }),
})
